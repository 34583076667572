import {
  type AnyVariables,
  type TypedDocumentNode,
  type UseMutationResponse,
  type OperationContext,
  useMutation as useMutationBase,
} from '@urql/vue';
import { type DocumentNode } from 'graphql';

export function useMutation<T = any, V extends AnyVariables = AnyVariables>(
  query: TypedDocumentNode<T, V> | DocumentNode | string
): UseMutationResponse<T, V> {
  const { executeMutation: executeMutationBase, ...response } =
    useMutationBase(query);
  return {
    executeMutation: async (
      variables: V,
      context?: Partial<OperationContext>
    ) => {
      const result = await executeMutationBase(variables, context);
      if (result.error) throw result.error;
      return result;
    },
    ...response,
  };
}
